import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Outlet } from "react-router-dom";
import Header from "../Header/Header";
import AboutSection from "../AboutSection/AboutSection";
import { useDispatch, useSelector } from "react-redux";
import { setShowGitOverlay } from "../../store/actions";
import GetInTouch from "../GetInTouch/GetInTouch";
import "./Layout.scss";

const Layout = () => {
  const location = useLocation();

  const dispatch = useDispatch();
  const showGITOverlay = useSelector((state) => state.showGITOverlay);

  useEffect(() => {
    if (showGITOverlay) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [showGITOverlay]);

  return (
    <div className="layout-page-container">
      <Header />
      <Outlet />

      {showGITOverlay && (
        <GetInTouch onClose={() => dispatch(setShowGitOverlay(false))} />
      )}

      <AboutSection location={location.pathname} />
    </div>
  );
};

export default Layout;
