import { ref, getDownloadURL } from "firebase/storage";
import {
  ref as dbRef,
  startAfter,
  limitToFirst,
  get,
  query,
  update,
  orderByKey,
} from "firebase/database";
import { storage, database } from "./firebase";
import emailjs, { EmailJSResponseStatus } from "@emailjs/browser";
import { GALAXYA15, IPAD, IPHONE16, PER_PAGE } from "./enums.js";

export const emailRegex =
  /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

export const sendEmail = async (
  email_to,
  to_name,
  message,
  from_name,
  from_email,
  template = "template_jjalv7h"
) => {
  try {
    const templateParams =
      template === "template_jjalv7h"
        ? {
            to_name,
            email_to,
            message,
          }
        : {
            email_to,
            message,
            from_name,
            from_email,
          };
    await emailjs.send(
      process.env.REACT_APP_EMAILJS_SERVICE_ID,
      template,
      templateParams,
      {
        publicKey: process.env.REACT_APP_EMAILJS_PUBLIC_KEY,
      }
    );
    return true;
  } catch (e) {
    if (e instanceof EmailJSResponseStatus) {
      console.log("EMAILJS FAILED...", e);
    }
    return false;
  }
};

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const getBoughtDownloadURL = async (
  device,
  resolution,
  orientation = "horizontal",
  itemName
) => {
  try {
    const name = itemName.split(".")[0];
    const vidURL = await getDownloadURL(
      ref(
        storage,
        `${device}/${resolution.toLowerCase()}/${orientation}/${name}.mov`
      )
    );
    return vidURL;
  } catch (e) {
    console.log("Getting bought download url error: ", e.message);
  }
};

export const snapshotToArray = async (snapshot) => {
  const arr = [];
  if (!snapshot) return arr;
  await snapshot.forEach((child) => {
    arr.push(child);
  });
  return arr;
};

export const formatAndUpdate = async (snapArr, device, currentVids = []) => {
  const newVids = [...currentVids];
  for (let i = 0; i < snapArr.length; i++) {
    const child = snapArr[i];
    const keywords = child.child("keywords").val();
    const orientation = child.key.includes("H_CAM")
      ? "horizontal"
      : child.key.includes("V_CAM")
      ? "vertical"
      : null;
    const vidFormat =
      device === "galaxya15" ||
      device === "ipad" ||
      (device === "iphone16" && orientation === "vertical")
        ? ".mp4"
        : ".mov";
    const title = child.child("title").val();
    const usage = child.child("usage").val();
    const framerate = child.child("framerate").val() || "24 FPS";
    const codec = child.child("framerate").val() || "MOV ProRes 422";
    var thumbnail = child.child("thumbnail").val();
    var video = child.child("video").val();
    if (!video) {
      video = await getDownloadURL(
        ref(storage, `${device}/sd/${orientation}/${child.key}${vidFormat}`)
      );
      await update(dbRef(database, `videos/${device}/${child.key}`), {
        video,
      });
    }
    if (!thumbnail) {
      thumbnail = await getDownloadURL(
        ref(storage, `${device}/preview/${orientation}/${child.key}.jpg`)
      );
      await update(dbRef(database, `videos/${device}/${child.key}`), {
        thumbnail,
      });
    }
    newVids.push({
      keywords: keywords.split(","),
      code: device,
      orientation,
      fullName: child.key,
      title,
      framerate,
      codec,
      resolution: [
        {
          id: "720p",
          type: "HD (1920x1080px)",
          price: 39,
        },
        {
          id: "2160p",
          type: "4K (3840x2160px)",
          price: 99,
        },
      ],
      usage,
      url: video,
      thumbnail,
    });
  }
  return newVids;
};

export const fetchVideos = async (device, currentVids = [], page = 1) => {
  return new Promise(async (res, rej) => {
    if (device === "all") {
      try {
        const perDevice = Math.floor(PER_PAGE / 3);
        let newCurrentVids = [...currentVids];
        const prevPage = page > 1 ? page - 1 : page;
        let startingPoint;

        startingPoint = [...currentVids][perDevice * prevPage - 1] || null;
        if (
          !startingPoint & (page === 1) ||
          startingPoint?.url?.includes(IPHONE16)
        ) {
          const ip16 = startingPoint
            ? await get(
                query(
                  dbRef(database, `videos/${IPHONE16}`),
                  orderByKey(),
                  startAfter(startingPoint?.fullName),
                  limitToFirst(perDevice)
                )
              )
            : await get(
                query(
                  dbRef(database, `videos/${IPHONE16}`),
                  orderByKey(),
                  limitToFirst(perDevice)
                )
              );
          const ip16Arr = await snapshotToArray(ip16);
          newCurrentVids = await formatAndUpdate(
            ip16Arr,
            IPHONE16,
            newCurrentVids
          );
        }

        startingPoint = [...currentVids][perDevice * prevPage * 2 - 1] || null;
        if (
          !startingPoint & (page === 1) ||
          startingPoint?.url?.includes(GALAXYA15)
        ) {
          const galaxya15 = startingPoint
            ? await get(
                query(
                  dbRef(database, `videos/${GALAXYA15}`),
                  orderByKey(),
                  startAfter(startingPoint?.fullName),
                  limitToFirst(perDevice)
                )
              )
            : await get(
                query(
                  dbRef(database, `videos/${GALAXYA15}`),
                  orderByKey(),
                  limitToFirst(perDevice)
                )
              );
          const ga15Arr = await snapshotToArray(galaxya15);
          newCurrentVids = await formatAndUpdate(
            ga15Arr,
            GALAXYA15,
            newCurrentVids
          );
        }

        startingPoint = [...currentVids][perDevice * prevPage * 3 - 1] || null;
        if (
          !startingPoint & (page === 1) ||
          startingPoint?.url?.includes(IPAD)
        ) {
          const ipad = startingPoint
            ? await get(
                query(
                  dbRef(database, `videos/${IPAD}`),
                  orderByKey(),
                  startAfter(startingPoint?.fullName),
                  limitToFirst(perDevice)
                )
              )
            : await get(
                query(
                  dbRef(database, `videos/${IPAD}`),
                  orderByKey(),
                  limitToFirst(perDevice)
                )
              );
          const ipadArr = await snapshotToArray(ipad);
          newCurrentVids = await formatAndUpdate(ipadArr, IPAD, newCurrentVids);
        }

        const isLastPage = newCurrentVids.length === currentVids.length;
        res({
          currentVids: newCurrentVids,
          nextPage: isLastPage ? page : page + 1,
        });
      } catch (e) {
        console.log("Firebase error: ", e.message);
        rej({ currentVids, nextPage: page });
      }
    } else {
      try {
        let newCurrentVids = [...currentVids];

        const startingPoint = [...currentVids].pop();

        const deviceQ = startingPoint
          ? await get(
              query(
                dbRef(database, `videos/${device}`),
                orderByKey(),
                startAfter(startingPoint?.fullName),
                limitToFirst(PER_PAGE)
              )
            )
          : await get(
              query(
                dbRef(database, `videos/${device}`),
                orderByKey(),
                limitToFirst(PER_PAGE)
              )
            );
        const deviceQArr = await snapshotToArray(deviceQ);
        newCurrentVids = await formatAndUpdate(
          deviceQArr,
          device,
          newCurrentVids
        );

        res({
          currentVids: newCurrentVids,
          nextPage:
            newCurrentVids.length === currentVids.length ? page : page + 1,
        });
      } catch (e) {
        console.log("Firebase error: ", e.message);
        rej({ currentVids, nextPage: page });
      }
    }
  });
};

export const getVideoData = async (device, title) => {
  try {
    const videoRef = dbRef(database, `videos/${device}/${title}`);
    const snapshot = await get(videoRef);
    if (snapshot.exists()) {
      const formatedValue = await formatAndUpdate([snapshot], device, []);
      return formatedValue;
    } else {
      console.log("No data available");
      return null;
    }
  } catch (error) {
    console.error("Error getting video data:", error);
    return null;
  }
};
