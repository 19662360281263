import React from "react";
import "./Skeleton.scss";

const Skeleton = ({ classNames, variant = "rect", width, height, style }) => {
  return (
    <div
      className={`skeleton ${variant === "rect" ? "skeleton-rect" : ""} ${
        variant === "circle" ? "skeleton-circle" : ""
      } ${classNames}`}
      style={{
        width: width || "100%",
        height: height || "100%",
        ...style,
      }}
    ></div>
  );
};

export default Skeleton;
