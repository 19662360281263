import { useState } from "react";
import "./BuyNow.scss";
import Button from "../../../components/Button/Button";
import BuyIcon from "../../../images/icons/BuyIcon";

export default function BuyNow({ video }) {
  const [resolution, setResolution] = useState();
  const [notification, setNotification] = useState();
  const [loaded, setLoaded] = useState(false);

  const localSetBoughtVideo = (selectedVideo) => {
    if (selectedVideo) {
      const savedData = {
        code: selectedVideo.code,
        fullName: selectedVideo.fullName,
        boughtResolution: selectedVideo.boughtResolution,
      };
      localStorage.setItem("boughtVid", JSON.stringify(savedData));
    }
  };

  const buyVideo = async (e) => {
    if (resolution) {
      video.boughtResolution = resolution;

      localSetBoughtVideo(video);
      if (resolution.toLowerCase() === "hd") {
        window.location.href = process.env.REACT_APP_HD_LINK;
      }
      if (resolution.toLowerCase() === "4k") {
        window.location.href = process.env.REACT_APP_4K_LINK;
      }
    } else {
      setNotification("Select resolution.");
      setTimeout(() => {
        setNotification("");
      }, 4000);
    }
  };

  const chooseResolution = (e) => {
    const resol = e.currentTarget.value;
    setResolution(resol.split(" ")[0]);
  };

  const playVideo = (e) => {
    try {
      const vid = e.target;
      setLoaded(true);
      vid.play();
    } catch (e) {
      console.log("play error ", e.message);
    }
  };

  return (
    <div className="page-wrapper buy-now-container">
      <div
        className={`thumbnail ${video.orientation}`}
        alt={video.code}
        style={{
          backgroundImage: !loaded ? `url(${video.thumbnail})` : "url()",
        }}
      >
        <video name={video.code} onCanPlay={playVideo} muted loop>
          <source src={video.url} type="video/mp4" />
        </video>
      </div>

      <div className={`video-info ${video.orientation}`}>
        <div className="buy-vid-info">
          <span className="video-title bold">{video.title}</span>
        </div>

        <div className="buy-action">
          <span className="video-meta-container">
            <span className="video-meta">
              <span className="bold">Frame rate: </span>
              <span>{video.framerate}</span>
            </span>

            <span className="video-meta">
              <span className="bold">Codec: </span>
              <span>{video.codec}</span>
            </span>
          </span>

          <div className="price-container">
            <fieldset className="resolution-type">
              {video?.resolution?.map((r) => (
                <span key={r.id} className="radio">
                  <span className="res-info">
                    <input
                      onChange={chooseResolution}
                      type="radio"
                      id={r.id}
                      name="resolution"
                      value={r.type}
                    />
                    <label htmlFor={r.id}>{r.type}</label>
                  </span>
                  <span className="price bold">${r.price}</span>
                </span>
              ))}
            </fieldset>

            {notification && (
              <span className="notification-text">{notification}</span>
            )}
          </div>

          <Button onClick={buyVideo} classNames="buy-now-btn">
            <div className="icon">
              <BuyIcon />
            </div>
            Buy Now
          </Button>

          <p className="legend">
            Don't see what you're looking for? Just{" "}
            <a
              className="link blue"
              href={`mailto:${process.env.REACT_APP_CONTACT_EMAIL}`}
            >
              email us
            </a>{" "}
            and we will do our best to get the exact footage you need.
          </p>
        </div>
      </div>
    </div>
  );
}
