import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import BuyIcon from "../../images/icons/BuyIcon";
import Skeleton from "../../components/Skeleton/Skeleton";
import "./VideoItem.scss";

function VideoItem({ video, index }) {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  const handleLoadedData = () => {
    setIsLoading(false);
  };

  const handleClickOnVideo = (e, name, device) => {
    e?.preventDefault();

    if (!name || !device) {
      console.warn("Missing name or device for navigation.");
      return;
    }

    const encodedTitle = encodeURIComponent(name.trim());
    const encodedDevice = encodeURIComponent(device.trim());

    navigate(`/devices/${encodedDevice}/${encodedTitle}`);
  };

  const playVideo = (e) => {
    const vid = e?.target;
    if (vid?.readyState === 4) {
      vid.play().catch((err) => {
        console.warn("Error playing video:", err.message);
      });
    } else {
      console.warn("Video is not ready to play or target is invalid.");
    }
  };

  const pauseVideo = (e) => {
    const vid = e?.target;
    if (vid?.readyState === 4) {
      vid.pause();
    } else {
      console.warn("Video is not ready to pause or target is invalid.");
    }
  };

  return (
    <div
      name={video.fullName}
      onClick={(e) => handleClickOnVideo(e, video.fullName, video.code)}
      className={`video-thumb ${video.orientation} button`}
    >
      {isLoading && (
        <Skeleton
          variant="rect"
          width="100%"
          height="100%"
          classNames="video-skeleton"
        />
      )}

      <video
        name={video.fullName}
        onMouseEnter={playVideo}
        onMouseLeave={pauseVideo}
        muted
        className={`list-video ${isLoading ? "hidden" : ""}`}
        id={`video-${index}`}
        onLoadedData={handleLoadedData}
      >
        <source src={video.url} type="video/mp4" />
      </video>
      <div name={video.fullName} className="button-buy">
        <BuyIcon color="#000000" />
      </div>
    </div>
  );
}

export default VideoItem;
