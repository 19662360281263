import React from "react";
import { Link, NavLink } from "react-router-dom";
import TextLogo from "../../images/text-logo.svg";
import { GALAXYA15TITLE, IPHONE16TITLE, IPADTITLE } from "../../helpers/enums";
import "./Header.scss";

export default function Header() {
  return (
    <div className="header-container">
      <header className="page-wrapper">
        <div className="left-header">
          <Link to={"/"}>
            <img className="logo" alt="unreal hands logo" src={TextLogo} />
          </Link>
          <nav>
            <NavLink
              to={"/devices/iphone16"}
              className={({ isActive, isPending }) =>
                isPending
                  ? "pending-nav-link"
                  : isActive
                  ? "active-nav-link"
                  : "nav-link"
              }
            >
              {IPHONE16TITLE}
            </NavLink>
            <NavLink
              to={"/devices/galaxya15"}
              className={({ isActive, isPending }) =>
                isPending
                  ? "pending-nav-link"
                  : isActive
                  ? "active-nav-link"
                  : "nav-link"
              }
            >
              {GALAXYA15TITLE}
            </NavLink>
            {/* <NavLink to={"/devices/google-pixel-8"} className={({ isActive, isPending }) => isPending ? "pending-nav-link" : isActive ? "active-nav-link" : "nav-link"} >
                            Google Pixel 8
                        </NavLink> */}
            <NavLink
              to={"/devices/ipad"}
              className={({ isActive, isPending }) =>
                isPending
                  ? "pending-nav-link"
                  : isActive
                  ? "active-nav-link"
                  : "nav-link"
              }
            >
              {IPADTITLE}
            </NavLink>
          </nav>
        </div>
        <div className="alt-nav">
          <Link className="link faq" to={"/faq"}>
            FAQ
          </Link>
        </div>
      </header>
    </div>
  );
}
