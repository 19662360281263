import React from "react";

function BuyIcon({ width, height, color }) {
  return (
    <svg
      width={width ? width : "22"}
      height={height ? height : "22"}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.43733 0.349961C1.19699 -0.0110963 0.709921 -0.108661 0.349421 0.132044C-0.0110792 0.372748 -0.108494 0.860573 0.13184 1.22163L1.62896 3.47078L3.89677 11.9882C4.48181 14.1855 6.46869 15.7143 8.73924 15.7143H18.8281C19.2613 15.7143 19.6126 15.3625 19.6126 14.9286C19.6126 14.4947 19.2613 14.1429 18.8281 14.1429H8.73924C7.5042 14.1429 6.39134 13.4844 5.78202 12.4609L19.0433 11.1124C20.0648 11.0085 20.9012 10.2533 21.1099 9.24641L21.95 5.19348C22.2533 3.7302 21.1378 2.35722 19.6456 2.35722H2.77343L1.43733 0.349961ZM5.24056 10.9364L3.3747 3.92864H19.6456C20.143 3.92864 20.5149 4.3863 20.4138 4.87406L19.5737 8.92699C19.5041 9.26263 19.2253 9.51435 18.8848 9.54898L5.24056 10.9364Z"
        fill={color ? color : "white"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.7071 19.6429C4.7071 18.3411 5.76079 17.2857 7.06059 17.2857C8.3604 17.2857 9.41409 18.3411 9.41409 19.6429C9.41409 20.9447 8.3604 22 7.06059 22C5.76079 22 4.7071 20.9447 4.7071 19.6429ZM7.06059 18.8572C6.62733 18.8572 6.27609 19.2089 6.27609 19.6429C6.27609 20.0768 6.62733 20.4286 7.06059 20.4286C7.49386 20.4286 7.84509 20.0768 7.84509 19.6429C7.84509 19.2089 7.49386 18.8572 7.06059 18.8572Z"
        fill={color ? color : "white"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.9056 19.6429C14.9056 18.3411 15.9593 17.2857 17.2591 17.2857C18.5589 17.2857 19.6126 18.3411 19.6126 19.6429C19.6126 20.9447 18.5589 22 17.2591 22C15.9593 22 14.9056 20.9447 14.9056 19.6429ZM17.2591 18.8572C16.8258 18.8572 16.4746 19.2089 16.4746 19.6429C16.4746 20.0768 16.8258 20.4286 17.2591 20.4286C17.6924 20.4286 18.0436 20.0768 18.0436 19.6429C18.0436 19.2089 17.6924 18.8572 17.2591 18.8572Z"
        fill={color ? color : "white"}
      />
    </svg>
  );
}

export default BuyIcon;
