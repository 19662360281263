export const GALAXYA15 = "galaxya15";
export const GALAXYA15TITLE = "Samsung Galaxy A15";

export const IPHONE16 = "iphone16";
export const IPHONE16TITLE = "Apple iPhone 16";

export const PIXEL8 = "pixel8";
export const PIXEL8TITLE = "Google Pixel 8";

export const IPAD = "ipad";
export const IPADTITLE = "Apple iPad";

export const PER_PAGE = 18;

export const COMPANY_NAME = "Unreal Hands";
export const EMAIL_TEMPLATE_ID = "template_jjalv7h";
