import { useState, useRef, useEffect } from "react";
import { sendEmail } from "../../helpers";
import "./GetInTouch.scss";
import Button from "../Button/Button";
import Input from "../Input/Input";
import Textarea from "../Textarea/Textarea";
import { COMPANY_NAME, EMAIL_TEMPLATE_ID } from "../../helpers/enums";
import Loader from "../../images/loading.gif";

export default function GetInTouch({ onClose }) {
  const modalRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mediaFiles: "",
    details: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const [sentMediaFiles, setSentMediaFiles] = useState();
  const [notification, setNotification] = useState({});

  const handlerSubmit = async (event) => {
    event.preventDefault();
    event.stopPropagation();

    const { name, mediaFiles, details, email } = formData;

    if (sentMediaFiles === mediaFiles) {
      return showNotification(
        "You have already sent these files, we will get back to you soon."
      );
    }

    const message = `Customer Name: ${name} \n Customer Email: ${email} \n URLs: ${mediaFiles} \n Message Details: ${details}`;

    try {
      setIsLoading(true);
      const sent = await sendEmail(
        process.env.REACT_APP_CONTACT_EMAIL,
        COMPANY_NAME,
        message,
        name,
        email,
        EMAIL_TEMPLATE_ID
      );

      if (sent) {
        setSentMediaFiles(mediaFiles);
        showNotification(
          "Message sent successfully, we will reach out soon.",
          "success"
        );
      } else {
        throw new Error("Email sending failed.");
      }
    } catch (error) {
      showNotification(`Something went wrong`, "error");
    } finally {
      setIsLoading(false);
    }
  };

  const showNotification = (message, type = "") => {
    setNotification({ type, message });
    setTimeout(() => {
      setNotification({});
    }, 4000);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  return (
    <div className="git-overlay">
      <div className="form-modal-container">
        <div className="git-form-container" ref={modalRef}>
          <h3 className="git-form-title">
            Green screen replacement service request
          </h3>

          <p className="git-form-subtitle">
            To ensure we deliver a flawless green screen replacement service,
            please provide the necessary information using the form below.
          </p>

          <form id="git-contact-form" onSubmit={handlerSubmit}>
            <Input label="Name" required onChange={handleChange} name="name" />

            <Input
              label="E-mail"
              required
              onChange={handleChange}
              name="email"
              type="email"
            />

            <Input
              label="Media files(URLs)"
              note="(.jpg, .png, .mp4, .mov up to 20 Mb)"
              required
              onChange={handleChange}
              name="mediaFiles"
            />

            <Textarea
              name="details"
              note="Let us know any specific details, like desired age, skin tone or
                any specific adjustments for gestures and timing"
              onChange={handleChange}
              rows={10}
            />

            <div className="form-notes-container">
              <span className="required">*</span> - required fields. <br />{" "}
              After we receive your content, we’ll contact you to settle all the
              details. We offer 24-hour delivery once everything is confirmed!
            </div>

            <Button type="submit">Submit</Button>

            {notification && Object.keys(notification).length > 0 && (
              <span className={`form-notification ${notification.type || ""}`}>
                {notification.message}
              </span>
            )}
          </form>

          {isLoading && (
            <div className="loading">
              <img alt="videos loading" src={Loader} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
