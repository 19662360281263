import { useEffect, useState } from "react";
import { sendEmail, emailRegex } from "../../../helpers";
import LinkIcon from "../../../images/icons/LinkIcon";
import Button from "../../../components/Button/Button";
import Input from "../../../components/Input/Input";
import DowmloadIcon from "../../../images/icons/DowmloadIcon";
import "./Download.scss";
import Loader from "../../../images/loading.gif";

export default function Download({ video, downloadURL }) {
  const [email, setEmail] = useState();
  const [emailNotification, setEmailNotification] = useState({});
  const [loaded, setLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    return function cleanup() {
      localStorage.removeItem("boughtVid");
    };
  });

  const onChange = (e) => {
    setEmail(e.target.value);
  };

  const sendMail = async (e) => {
    e.preventDefault();

    if (!email || !emailRegex.test(email)) {
      showNotification("Please enter a valid email address", "error");
      return;
    }

    const name = email.split("@")[0];

    if (!downloadURL) {
      showNotification("Download URL is missing", "error");
      return;
    }

    try {
      setIsLoading(true);
      const emailSent = await sendEmail(email, name, downloadURL);
      if (emailSent) {
        showNotification("Email sent successfully!", "success");
      } else {
        showNotification("Failed to send email. Please try again.", "error");
      }
    } catch (error) {
      console.error("Error sending email:", error);
      showNotification(
        "An error occurred while sending the email. Please try again.",
        "error"
      );
    } finally {
      setIsLoading(false);
    }
  };

  const showNotification = (message, type = "") => {
    setEmailNotification({ type, message });
    setTimeout(() => {
      setEmailNotification({});
    }, 4000);
  };

  const downloadVideo = async () => {
    try {
      setIsLoading(true);
      const response = await fetch(downloadURL);
      const blob = await response.blob();

      const contentDisposition = response.headers.get("Content-Disposition");
      let fileName = "video";

      if (contentDisposition) {
        const match = contentDisposition.match(/filename="?([^"]+)"?/);
        if (match && match[1]) {
          fileName = match[1];
        }
      } else {
        fileName = downloadURL.split("/").pop() || "video";
      }

      const mimeToExt = {
        "video/mp4": "mp4",
        "video/webm": "webm",
        "video/ogg": "ogg",
      };
      const fileType = blob.type;
      const fileExtension =
        mimeToExt[fileType] || fileName.split(".").pop() || "unknown";

      if (!fileName.includes(".")) {
        fileName = `${fileName}.${fileExtension}`;
      }

      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = fileName;
      link.click();
    } catch (error) {
      console.error("Error downloading video:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const playVideo = (e) => {
    try {
      const vid = e.target;
      setLoaded(true);
      vid.play();
    } catch (e) {
      console.log("play error ", e.message);
    }
  };

  return (
    <div className="download-now-container page-wrapper">
      <span
        className={`thumbnail ${video.orientation}`}
        alt={video.code}
        style={{
          backgroundImage: !loaded ? `url(${video.thumbnail})` : "url()",
        }}
      >
        <video name={video.code} onCanPlay={playVideo} muted loop>
          <source src={video.url} type="video/mp4" />
        </video>
      </span>

      <div className={`download-info ${video.orientation}`}>
        <div className="buy-vid-info">
          <Button onClick={downloadVideo} classNames="download-now-btn">
            <div className="icon">
              <DowmloadIcon />
            </div>
            Download file
          </Button>

          <span className="video-title">
            <span className="bold">Thank you!</span>
            <span>Please click the link above to download the file</span>
          </span>
        </div>

        <div className="buy-action">
          <span className="video-meta-container">
            <span className="bold">Please note:</span>
            <span>
              We do not store any used data on our website. However, if you want
              to get access to purchased file later, we kindly ask you to
              provide your email address. This is only for the purpose of
              sending the link, and we do not retain your email after that.
            </span>
          </span>

          <span className="contact-container">
            <Input
              onChange={onChange}
              placeholder="Your e-mail address"
              name="email"
              type="email"
            />

            <Button
              onClick={sendMail}
              classNames="send-link-btn"
              styleType="dark"
            >
              <div className="icon">
                <LinkIcon />
              </div>
              Send link
            </Button>

            {emailNotification && Object.keys(emailNotification).length > 0 && (
              <span
                className={`email-notification ${emailNotification.type || ""}`}
              >
                {emailNotification.message}
              </span>
            )}
          </span>
        </div>

        {isLoading ? (
          <div className="loading">
            <img alt="videos loading" src={Loader} />
            <p>
              The download might take a while due to the file size. Please wait
              as we complete the process.
            </p>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}
