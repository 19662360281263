import React, { useEffect, useState } from "react";
import "./SuccessPayment.scss";
import { useNavigate, useLocation } from "react-router-dom";

function SuccessPayment() {
  const [isCorrectPage, setIsCorrectPage] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const boughtVid = JSON.parse(localStorage.getItem("boughtVid"));

    if (!boughtVid) return;

    const params = new URLSearchParams(location.search);
    const checkoutId = params.get("checkout_id");

    if (checkoutId) {
      setIsCorrectPage(true);
      const { code, fullName, boughtResolution } = boughtVid;
      navigate(`/devices/${code}/${fullName}`, {
        state: { hiddenParam: boughtResolution },
      });
    }
  }, [location.search, navigate]);

  return (
    <div className="page-container">
      <div className="text-container">
        {isCorrectPage ? (
          <>
            <h1>Great! Your payment was successful.</h1>
            <p>
              Thank you for your purchase! <b>You will be redirected</b> to the
              product page to download your file in <b>just a moment</b>.
            </p>
          </>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default SuccessPayment;
