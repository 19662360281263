import {
  SHOW_GIT_OVERLAY,
  SET_BOUGHT_VIDEO,
  SET_CURRENT_VIDEOS,
} from "./actionTypes";

export const setShowGitOverlay = (videos) => ({
  type: SHOW_GIT_OVERLAY,
  payload: videos,
});

export const setBoughtVideo = (video) => ({
  type: SET_BOUGHT_VIDEO,
  payload: video,
});

export const setCurrentVideos = (videos) => ({
  type: SET_CURRENT_VIDEOS,
  payload: videos,
});
