import React, { useEffect, useState } from "react";
import "./index.scss";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { getVideoData, getBoughtDownloadURL } from "../../helpers";
import { Helmet } from "react-helmet-async";
import Loader from "../../images/loading.gif";
import { useDispatch, useSelector } from "react-redux";
import {
  GALAXYA15,
  GALAXYA15TITLE,
  IPHONE16,
  IPHONE16TITLE,
  IPAD,
  IPADTITLE,
  PIXEL8,
  PIXEL8TITLE,
} from "../../helpers/enums";
import BuyNow from "./BuyNow/BuyNow";
import { setBoughtVideo } from "../../store/actions";
import Download from "./Download/Download";

const DeviceDetail = () => {
  const dispatch = useDispatch();
  const boughtVideo = useSelector((state) => state.boughtVideo);

  const { device, title } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const [downloadURL, setDownloadURL] = useState();
  const [videoData, setVideoData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const hiddenParam = location.state?.hiddenParam;

  useEffect(() => {
    if (!videoData) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [videoData]);

  useEffect(() => {
    const fetchVideoData = async () => {
      const data = await getVideoData(device, title);
      if (data && data.length > 0) {
        setVideoData(data[0]);
      } else {
        setVideoData(null);
      }
    };

    if (device && title) {
      fetchVideoData();
    }
  }, [device, title]);

  useEffect(() => {
    if (videoData && hiddenParam) {
      dispatch(setBoughtVideo(videoData));
    } else {
      dispatch(setBoughtVideo(null));
    }
  }, [videoData, hiddenParam, dispatch]);

  useEffect(() => {
    if (boughtVideo && !hiddenParam) {
      localStorage.removeItem("boughtVid");
      dispatch(setBoughtVideo(null));
    } else if (boughtVideo) {
      async function setupDLURL(device, resolution, orientation, itemName) {
        setIsLoading(true);
        const dlURL = await getBoughtDownloadURL(
          device,
          resolution,
          orientation,
          itemName
        );
        setDownloadURL(dlURL);
        setIsLoading(false);
      }
      setupDLURL(
        boughtVideo.code,
        hiddenParam,
        boughtVideo.orientation,
        boughtVideo.fullName
      );
    }
  }, [boughtVideo, hiddenParam, dispatch]);

  const determineDeviceTitle = () => {
    const deviceTitles = {
      [GALAXYA15]: GALAXYA15TITLE,
      [IPHONE16]: IPHONE16TITLE,
      [PIXEL8]: PIXEL8TITLE,
      [IPAD]: IPADTITLE,
    };

    return deviceTitles[device] || "";
  };

  const handlerBackBtn = () => {
    const encodedDevice = encodeURIComponent(device);
    navigate(`/devices/${encodedDevice}/`);
  };

  return (
    <div className="videos-section">
      {videoData ? (
        <>
          <Helmet>
            <title>{videoData?.title}</title>
            <meta name="keywords" content={videoData.keywords} />
          </Helmet>

          <div className="back-link page-wrapper">
            <span onClick={handlerBackBtn} className="link">
              {`< Back to ${determineDeviceTitle()}`}{" "}
            </span>
          </div>

          {boughtVideo ? (
            <Download downloadURL={downloadURL} video={videoData} />
          ) : (
            <BuyNow video={videoData} />
          )}
        </>
      ) : (
        ""
      )}

      {isLoading ? (
        <span className="loading">
          <img alt="videos loading" src={Loader} />
        </span>
      ) : (
        ""
      )}
    </div>
  );
};

export default DeviceDetail;
