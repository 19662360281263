import React from "react";
import "./FAQ.scss";
import { COMPANY_NAME } from "../../helpers/enums";
import { Helmet } from "react-helmet-async";

function FAQ() {
  return (
    <div className="page-wrapper">
      <Helmet>
        <title>Frequently Asked Questions - {COMPANY_NAME}</title>
      </Helmet>

      <div className="page-faq-container">
        <h1>Frequently Asked Questions (FAQ)</h1>

        <div className="text-container">
          <ol>
            <li>
              <h3>What is included in your green screen video mock-ups?</h3>
              <p>
                Our green screen video mock-ups feature professionally shot
                footage of devices such as iPhones, Samsung phones, and more,
                with a green screen ready for customization. These mock-ups are
                perfect for showcasing your app, website, or content in a
                realistic setting.
              </p>
            </li>
            <hr />
            <li>
              <h3>How does the green screen replacement service work?</h3>
              <p>
                We replace the green screen in the mock-ups with your custom
                content, ensuring seamless integration. This includes:
                <ul>
                  <li>Matching screen light leaks and reflections.</li>
                  <li>Adjusting for proper angles and screen gestures.</li>
                  <li>
                    Enhancing video quality to fit your branding. Simply send us
                    your content, and we’ll handle the rest!
                  </li>
                </ul>
              </p>
            </li>
            <hr />
            <li>
              <h3>
                Can you adjust the lighting, background, or other elements?
              </h3>
              <p>
                Yes, we offer additional customization options such as:
                <ul>
                  <li>Changing lighting conditions to match your brand.</li>
                  <li>Altering background settings.</li>
                  <li>Adjusting skin tones and other visual elements.</li>
                </ul>
                For specific requests, please contact us, and we’ll tailor the
                video to your needs.
              </p>
            </li>
            <hr />
            <li>
              <h3>How long does it take to complete the video replacement?</h3>
              <p>
                We pride ourselves on quick turnaround times. Standard projects
                are delivered within 24 hours after finalizing the details. For
                more complex customizations, timelines may vary.
              </p>
            </li>
            <hr />
            <li>
              <h3>Are the images AI-generated?</h3>
              <p>
                Devices, poses, and hands are <b>not AI-generated.</b> However,
                some backgrounds are AI-generated, which allows us to offer a
                diverse collection of beautiful and creative options for your
                projects.
              </p>
            </li>
            <hr />
            <li>
              <h3>What devices are supported in your mock-ups?</h3>
              <p>
                Our mock-ups cover a range of devices, including:
                <ul>
                  <li>Apple iPhone models.</li>
                  <li>Samsung Galaxy phones.</li>
                  <li>Google Pixel phones.</li>
                  <li>Other devices like tablets or even car displays.</li>
                </ul>
                For more details, browse our categories or get in touch with us
                to confirm compatibility.
              </p>
            </li>
            <hr />
            <li>
              <h3>Can I use the images commercially?</h3>
              <p>
                Yes, you can use the generated images to promote your apps
                online, in print, or on social media platforms. However, it is
                <b>
                  prohibited to resell the templates or images generated by
                  UnrealHands
                </b>{" "}
                in any form.
              </p>
            </li>
            <hr />
            <li>
              <h3>Do you provide custom-tailored video solutions?</h3>
              <p>
                Yes! For demanding projects, we offer custom camera movements,
                lighting adjustments, and bespoke solutions to elevate your
                video projects. Reach out to us for a consultation.
              </p>
            </li>
            <hr />
            <li>
              <h3>How much does the service cost?</h3>
              <p>
                Our green screen replacement service starts at{" "}
                <b>$79.00 per video replacement.</b> Additional customization
                services may involve extra charges. Contact us for detailed
                pricing based on your requirements.
              </p>
            </li>
            <hr />
            <li>
              <h3>What format will the final video be delivered in?</h3>
              <p>
                We deliver the final video in commonly used formats like{" "}
                <b>MP4</b> or <b>MOV</b>, optimized for online use. If you
                require specific formats or resolutions, let us know during the
                order process.
              </p>
            </li>
            <hr />
            <li>
              <h3>How do I place an order?</h3>
              <p>
                To place an order:
                <ol>
                  <li>Select the mock-up of your choice from our catalog.</li>
                  <li>
                    Click on <b>Get in Touch</b> and provide your content or
                    customization details.
                  </li>
                  <li>
                    We’ll review your request and guide you through the process.
                  </li>
                </ol>
              </p>
            </li>
            <hr />
            <li>
              <h3>Do you offer bulk discounts?</h3>
              <p>
                Yes, we provide discounts for bulk orders or long-term
                collaborations. Please contact us with your project details for
                a custom quote.
              </p>
            </li>
            <hr />
            <li>
              <h3>What if I’m not satisfied with the final product?</h3>
              <p>
                Customer satisfaction is our priority. If you’re not satisfied
                with the final product, let us know, and we’ll work with you to
                address any concerns.
              </p>
            </li>
            <hr />
            <li>
              <h3>Do you offer refunds?</h3>
              <p>
                As our products involve digital services, refunds are generally
                not provided once the work begins. However, exceptions may apply
                in certain circumstances. Please review our Terms and Conditions
                for more details.
              </p>
            </li>
          </ol>
        </div>
      </div>
    </div>
  );
}

export default FAQ;
