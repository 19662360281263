import React from "react";

function FAQIcon({ width, height, color }) {
  return (
    <svg
      width={width ? width : "29"}
      height={height ? height : "28"}
      viewBox="0 0 29 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.5 8C12.8431 8 11.5 9.34315 11.5 11C11.5 11.5523 11.0523 12 10.5 12C9.94771 12 9.5 11.5523 9.5 11C9.5 8.23858 11.7386 6 14.5 6C17.2614 6 19.5 8.23858 19.5 11C19.5 12.4965 18.5323 13.6453 17.6661 14.4768C17.2887 14.8392 16.8803 15.1864 16.5104 15.501C16.4413 15.5597 16.3734 15.6175 16.3074 15.6738C15.9951 15.9406 15.7235 16.1789 15.4977 16.4017C15.4625 16.9221 15.0293 17.3333 14.5 17.3333C13.9477 17.3333 13.5 16.8856 13.5 16.3333V16C13.5 15.7557 13.5894 15.5198 13.7514 15.3369C14.1192 14.9218 14.5772 14.5215 15.0082 14.1533C15.0809 14.0911 15.1531 14.0297 15.2248 13.9687L15.2259 13.9678C15.5963 13.6526 15.9511 13.3508 16.2811 13.034C17.0991 12.2487 17.5 11.6105 17.5 11C17.5 9.34315 16.1569 8 14.5 8Z"
        fill="white"
      />
      <path
        d="M15.5 20C15.5 20.5523 15.0523 21 14.5 21C13.9477 21 13.5 20.5523 13.5 20C13.5 19.4477 13.9477 19 14.5 19C15.0523 19 15.5 19.4477 15.5 20Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5 0C6.7728 3.57628e-07 0.5 6.23382 0.5 13.9343C0.5 21.6174 6.71385 28 14.4583 28C18.3404 28 22.1893 27.962 26.0679 27.9237L27.4681 27.91C27.7927 27.9068 28.0957 27.7462 28.2805 27.4793C28.4653 27.2123 28.5091 26.8722 28.3978 26.5672L26.4426 21.2092C27.7475 19.0914 28.5 16.5991 28.5 13.9343C28.5 6.23382 22.2272 -3.57628e-07 14.5 0ZM2.5 13.9343C2.5 7.34793 7.8678 2 14.5 2C21.1322 2 26.5 7.34793 26.5 13.9343C26.5 16.3746 25.7646 18.6414 24.5019 20.5309C24.3247 20.796 24.2846 21.1297 24.3939 21.4293L26.034 25.9239C22.1591 25.9622 18.3251 26 14.4583 26C7.84343 26 2.5 20.538 2.5 13.9343Z"
        fill={color ? color : "white"}
      />
    </svg>
  );
}

export default FAQIcon;
