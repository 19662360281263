import React from "react";

function LinkIcon({ width, height, color }) {
  return (
    <svg
      width={width ? width : "30"}
      height={height ? height : "30"}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.2147 4.27787C30.1167 1.87234 27.775 -0.480253 25.3653 0.410671L2.5478 8.84693C0.143258 9.73595 -0.110517 13.0363 2.12983 14.2825L10.0866 18.7084C10.2652 18.8078 10.4091 18.9595 10.4989 19.1432L14.8763 28.0993C16.0323 30.4644 19.4563 30.3002 20.3806 27.8354L29.2147 4.27787ZM27.2784 3.74532L12.3032 18.2801L16.6732 27.2211C17.0585 28.0094 18.1999 27.9547 18.508 27.1331L27.2784 3.74532ZM10.8886 16.8659L3.10205 12.5347C2.35526 12.1193 2.43986 11.0192 3.24137 10.7228L25.818 2.37564L10.8886 16.8659Z"
        fill={color ? color : "white"}
      />
    </svg>
  );
}

export default LinkIcon;
