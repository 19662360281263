import React from "react";
import "./Textarea.scss";

const Textarea = ({
  label,
  value,
  onChange,
  placeholder,
  id,
  rows = 4,
  name,
  note,
  required,
  ...props
}) => {
  return (
    <div className="textarea-container">
      {(label || note) && (
        <label htmlFor={name}>
          {label}
          <span className={`note-container ${note ? "note-exist" : ""}`}>
            {note ? <span className="note">{note}</span> : ""}{" "}
            {required ? <span className="required-mark">*</span> : ""}:
          </span>
        </label>
      )}
      <textarea
        {...props}
        value={value}
        id={name}
        name={name}
        onChange={onChange}
        placeholder={placeholder}
        rows={rows}
      />
    </div>
  );
};

export default Textarea;
