import React from "react";
import "./Input.scss";

const Input = ({
  type = "text",
  value,
  onChange,
  placeholder,
  name,
  label,
  required = false,
  note,
  classNames = "",
  ...props
}) => {
  return (
    <div className={`common-input-container ${classNames}`}>
      {(label || note) && (
        <label htmlFor={name}>
          {label}
          <span className={`note-container ${note ? "note-exist" : ""}`}>
            {note ? <span className="note">{note}</span> : ""}{" "}
            {required ? <span className="required-mark">*</span> : ""}:
          </span>
        </label>
      )}
      <input
        type={type}
        id={name}
        name={name}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        required={required}
        {...props}
      />
    </div>
  );
};

export default Input;
