import { createBrowserRouter } from "react-router-dom";
import Devices from "../pages/Devices";
import Layout from "../components/Layout/Layout";
import DeviceDetail from "../pages/DeviceDetail";
import SuccessPayment from "../pages/SuccessPayment/SuccessPayment";
import FAQ from "../pages/FAQ/FAQ";

// Generating routes for devices
const createDeviceRoutes = () => [
  {
    path: "devices/:device",
    element: <Devices />,
  },
  {
    path: "devices/:device/:title",
    element: <DeviceDetail />,
  },
];

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: "",
        element: <Devices />,
      },
      ...createDeviceRoutes(),
      {
        path: "success",
        element: <SuccessPayment />,
      },
      {
        path: "faq",
        element: <FAQ />,
      },
    ],
  },
]);

export default router;
