import {
  SHOW_GIT_OVERLAY,
  SET_BOUGHT_VIDEO,
  SET_CURRENT_VIDEOS,
} from "./actionTypes";

const initialState = {
  showGITOverlay: false,
  boughtVideo: null,
  currentVideos: [],
};

export function commonReducer(state = initialState, action) {
  switch (action.type) {
    case SHOW_GIT_OVERLAY:
      return { ...state, showGITOverlay: action.payload };
    case SET_BOUGHT_VIDEO:
      return { ...state, boughtVideo: action.payload };
    case SET_CURRENT_VIDEOS:
      return { ...state, currentVideos: action.payload };
    default:
      return state;
  }
}
