import { GALAXYA15, IPAD, IPHONE16, PIXEL8 } from "../../helpers/enums.js";
import { useDispatch, useSelector } from "react-redux";
import { setShowGitOverlay } from "../../store/actions.js";
import "./AboutSection.scss";
import Button from "../Button/Button.jsx";
import FAQIcon from "../../images/icons/FAQIcon.jsx";

export default function AboutSection({ location }) {
  const dispatch = useDispatch();
  const showGITOverlay = useSelector((state) => state.common);

  const toggleGITOverlay = (e) => {
    e.preventDefault();
    dispatch(setShowGitOverlay(!showGITOverlay));
  };

  return (
    <div className="about-section">
      <div className="page-wrapper">
        <div className="about-sub-section">
          <div className="about-text">
            <span className="about-title">
              Green screen replacement and content integration service
            </span>

            <div className="about-details">
              <span>
                {`At UnrealHands, we can do more than just replace the green screen in our 
                            ${
                              location.includes(PIXEL8)
                                ? "Google Pixel 8"
                                : location.includes(IPHONE16)
                                ? "Apple iPhone 16"
                                : location.includes(IPAD)
                                ? "Apple iPad"
                                : location.includes(GALAXYA15)
                                ? "Samsung Galaxy a15 "
                                : "device"
                            } 
                            video mockups. We can change:`}
              </span>
              <ul>
                <li>age</li>
                <li>skin tones</li>
                <li>lighting</li>
                <li>background</li>
                <li>
                  and event{" "}
                  <span className="blue bold">
                    match screen light leaks, and gesture timing
                  </span>{" "}
                  to fit your custom videos perfectly.{" "}
                </li>
              </ul>
              <p>
                Say goodbye to Mocha tracking and tedious editing—just send us
                your content, and we'll handle the rest. Get professional,{" "}
                <span className="bold">ready-to-use</span> videos in no time
                with our <span className="bold">24-hour delivery</span> (after
                finalizing all details)
              </p>
              <p>
                For our most demanding clients, we also offer custom-tailored
                camera movements to elevate your video projects even further.
              </p>
            </div>
          </div>

          <div className="get-in-touch">
            <div className="git-illustration"></div>
            <div className="git-bottom">
              <Button
                onClick={toggleGITOverlay}
                styleType="secondary"
                classNames="get-in-touch-btn"
              >
                <div className="icon">
                  <FAQIcon />
                </div>
                Get in touch
              </Button>

              <span className="git-pricing">
                <span className="git-price">$79.00</span>
                <span className="git-price-details">Video+replacement</span>
              </span>
            </div>
          </div>
        </div>

        <div className="footer-container">
          <a
            target="_blank"
            rel="noreferrer"
            href="https://docs.google.com/document/d/1LfLm2i_iW78vZ6UFZndxfrl6Z1_zXRU0OUTnD5jmiGk/edit?tab=t.0"
          >
            Terms and Conditions
          </a>
        </div>
      </div>
    </div>
  );
}
